import React, { useEffect, useState } from "react"
// import { graphql, useStaticQuery } from "gatsby"

import Grid from "../components/grid"
import styled from "styled-components"
// import Image from "gatsby-image"
// const url = `/.netlify/functions/ig-function`;

// function getIgFeed() {
//   const [posts, setPosts] = useState([]);
//   useEffect(() => {
//     fetch(url)
//     .then(res => res.json())
//     .then(data => {
//       setPosts(data);
//     });
//   }, []);
//   return posts
// }

const InstagramFeed = () => {
  // const data = useStaticQuery(graphql`
  //   query Insta {
  //       allInstaNode(sort: {fields: timestamp, order: DESC}, limit: 12) {
  //         edges {
  //           node {
  //             id
  //             localFile {
  //               childImageSharp {
  //                 fluid {
  //                   ...GatsbyImageSharpFluid
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //     `)

  // const ig = getIgFeed();
  return (
    <Grid columns={3} gap="4px">
      {/* have to switch these two for dev and production due to netlify scipt */}
      {/* {data.allInstaNode.edges.map(node => {
                return (
                    <IGLink key={node.node.id}>
                        <a href={`https://www.instagram.com/p/${node.node.id}/`}>
                            <Image key={node.node.id} fluid={{ ...node.node.localFile.childImageSharp.fluid, aspectRatio: 1 }} />
                        </a>
                    </IGLink>
                )
            })
            } */}
      {/* {ig.map(igPost => (
        <IGLink key={igPost.id}>
          <a href={igPost.url} target="_blank" rel="noreferrer noopener">
            <img src={igPost.thumbnail} alt={igPost.caption} style={{
              marginBottom: "-0.4rem",
            }} />
          </a>
        </IGLink>
      ))} */}


    </Grid>
  )
}

export default InstagramFeed

const IGLink = styled.div`
transition: filter 0.5s ease;
filter: sepia(10%) brightness(80%);
&:hover {
    filter: sepia(0%) brightness(110%);
}

`