import React from "react"
import { Link, graphql } from "gatsby"



import SEO from "../components/seo"
import Button from "../components/button"
import Hero from "../components/hero"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { rhythm } from "../utils/typography"

import Calendar from "../components/calendar"
import InstagramFeed from "../components/instagram-feed"



const HeroText = styled.h1`
font-size: ${rhythm(2)};
`


const IndexPage = ({ data }) => {

  const heroImg = data.content.frontmatter.hero.childImageSharp.fluid.src

  return (
    <>
      <SEO
        title="Home"
        keywords={[`vocal lessons`, `choir conductor`, `choir`, `conductor`]}
      />
      <Hero imageSrc={heroImg}
        minHeight="75vh">
        <HeroText>{data.content.frontmatter.heading}</HeroText>
        <h3>{data.content.frontmatter.subheading}</h3>

      </Hero>
      <Content>
        <PageText>
      <MDXRenderer>
          {data.content.body}
        </MDXRenderer>
        </PageText>
        <ButtonGroup>
          <Link to={data.content.frontmatter.button1destination}>
            <Button>{data.content.frontmatter.button1}</Button>
          </Link>
          <Link to={data.content.frontmatter.button2destination}>
            <Button>{data.content.frontmatter.button2}</Button>
          </Link>
        </ButtonGroup>
 
        <Calendar showPast={false} size="32px" limit={true} amount={3} />
        <InstagramFeed />
      </Content>



    </>
  )
}


export default IndexPage

export const query = graphql`
query Hero {
  content: mdx(fileAbsolutePath: {regex: "/frontpage.md/"}) {
    frontmatter{
      heading
      subheading
      hero {
        childImageSharp {
          fluid(maxWidth: 2500) {
            src
          }
        }
      }
      button1
      button1destination
      button2
      button2destination
    }
    body
  }
}
`

const ButtonGroup = styled.div`
position: relative;

@media(max-width: 600px) {

}
`
const Content = styled.div`
position: relative;



@media(min-width: 600px){

}
@media(max-width: 320px){

}
`
const PageText = styled.div`
  text-align: center;
  @media(max-width: 450px){
    margin-top: ${rhythm(-1)};
  }
`