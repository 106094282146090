import React from "react"
import styled from "styled-components"

const GridInner = styled.div`
display: grid;

grid-template-columns: repeat(${props => props.columns || 2}, 1fr);
grid-template-rows: auto;
grid-gap: ${props => props.gap || '1px'};
align-items: center;
`
const GridOuter = styled.div`
margin-bottom: 1.75rem;
`

const Grid = (props) => {

    return (
        <GridOuter>
            <GridInner columns={props.columns} gap={props.gap}>
                {props.children}
            </GridInner>
        </GridOuter>
    )
}

export default Grid