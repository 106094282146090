import React from "react"
import styled from "styled-components"

const Button = props => (
  <ButtonWrapper props={props} onClick={props.onClick}>{props.children}</ButtonWrapper>
)

const ButtonWrapper = styled.button`
  display: block;
  border: none;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  padding: ${props => props.props.padding || "10px 25px"};
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;

  background: ${props => props.props.background || props.theme.colors.primary};
  color: ${props => props.props.color || props.theme.colors.background};
  font-size: ${props => props.props.fontSize || "18px"};
  font-weight: ${props => props.props.fontWeight || "500"};
  border-radius: ${props => props.props.radius || "0px"};
  margin: ${props => props.props.extraMargin || "auto"};
  margin-top: ${props => props.props.marginTop || "1rem"};
  margin-bottom: ${props => props.props.marginBottom || "1rem"};
  box-shadow: inset 0 0 20px 0px rgba(255, 255, 255, 0.0);
  transition: all 0.25s;

  &:hover {
    box-shadow: inset 0 0 50px 50px rgba(255, 255, 255, 0.125);
  }
`

export default Button
